/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
import { ProductName } from '@pypestream/api-services';
import { i18n } from '@pypestream/translations';
import { html, nothing, PropertyValueMap } from 'lit';
import { property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';

import { ifDefined } from 'lit-html/directives/if-defined.js';
import { isEqual } from 'lodash';
import { logos } from '../../assets/logos';
import megaMenuEmptyStateIcon from '../../assets/mega-menu-empty-state.svg?url';
import type { MenuItemWC } from '../../components';
import {
  BaseElement,
  customElement,
  watch,
} from '../../components/base-element';
import { Translate } from '../../components/base-element/mixins/translation-mixin';
import { scrollIntoView } from '../../components/base-element/utilities/scroll';
import { ScrollbarWC } from '../../components/scrollbar/scrollbar.wc';
import { CommonProject } from '../universal-nav/types';
import { handleGoHome } from '../utils';
import { smartService } from '../xstate/smart.xstate';
import { SmartContext } from '../xstate/smart.xstate-utils';

interface MegaMenuState {
  projects: CommonProject[];
  // selected project by mega menu pov
  selectedProject?: CommonProject;
  // Actual viewing project i.e. project representing this.projectId
  viewingProject?: CommonProject;
}

@customElement('ps-mega-menu')
export class MegaMenuWC extends Translate(BaseElement) {
  context: SmartContext;

  @watch('lang')
  async onLangChanged() {
    // @ts-ignore
    await this.reloadLanguage();
  }

  @property({ reflect: false }) projectId: string;

  @property({ reflect: false, type: Object }) selectedOrg: {
    name: string;
    id: string;
    icon?: string;
  } = {
    id: '',
    name: '',
  };

  @property({ reflect: false, type: Boolean }) isDisabledOrgs?: boolean = false;

  @property({ reflect: true }) app: ProductName = ProductName.Organization;

  @property({ reflect: false, type: Boolean }) hideGoHome?: boolean = false;

  @state() private megaMenuState: MegaMenuState = {
    projects: [],
  };

  @state() onProjectSelect: (projectId: string | undefined) => void;

  @state() onSelectionChange: (org: string | null) => void;

  @property() onManagerToolClick?: (route: string) => void;

  @state() private chatLink: string = '';

  @state() private isLoadingProjects: boolean = false;

  @state() private hasLoadingProjectsError: boolean = false;

  @watch('project', { waitUntilFirstUpdate: true })
  handleResetSelectedProject() {
    this.megaMenuState.selectedProject = this.megaMenuState.projects.find(
      ({ project: { id } }) => id === this.projectId
    );
  }

  private chatTimerId: NodeJS.Timeout;

  constructor() {
    super();

    this.init = this.init.bind(this);
  }

  async connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    smartService.subscribe((smartState) => {
      const currentUserInfo = this.context?.userInfo;
      const assignedProjects = this.context?.assignedProjects || [];

      // respect subscription updates and request for UI rerender.
      if (
        assignedProjects &&
        !isEqual(this.megaMenuState.projects, assignedProjects)
      ) {
        this.megaMenuState.projects = assignedProjects;

        if (this.megaMenuState.selectedProject) {
          const selectedProjectIndex = assignedProjects.findIndex(
            (project) =>
              project.project.id ===
              this.megaMenuState.selectedProject?.project.id
          );

          if (selectedProjectIndex >= 0) {
            this.megaMenuState.selectedProject.availableProducts =
              assignedProjects[selectedProjectIndex].availableProducts;
          } else {
            this.megaMenuState.selectedProject = undefined;
          }
        }
        this.requestUpdate();
      }

      this.context = smartState.context;

      if (this.context.userInfo?.defaultAccount) {
        const {
          defaultAccount: { allChildAccounts = [], ...defaultAccountDetails },
        } = this.context.userInfo;

        const selectChildOrg = [
          ...allChildAccounts,
          defaultAccountDetails,
        ].find((_childAcc) => _childAcc.id === smartState.context.accountId);

        if (selectChildOrg) {
          this.selectedOrg.name = selectChildOrg.name || '';
          this.selectedOrg.id = selectChildOrg.id || '';
          this.selectedOrg.icon = selectChildOrg.pictureFile?.url;
        }
      }
      this.isLoadingProjects =
        smartState.matches('projects.loading') ||
        (!assignedProjects?.length && smartState.matches('userInfo.loading'));

      this.hasLoadingProjectsError = smartState.matches('projects.loadError');

      if (
        currentUserInfo !== smartState.context.userInfo ||
        assignedProjects !== smartState.context.assignedProjects
      ) {
        this.init();
      }
    });

    const check = () => {
      const chatHolder =
        document.getElementsByClassName('woot-widget-holder')?.[0];
      const iframeUrl = chatHolder?.firstElementChild?.getAttribute('src');

      if (!iframeUrl) {
        this.chatTimerId = setTimeout(check, 100);
      } else {
        this.chatLink = iframeUrl;
        clearInterval(this.chatTimerId);
      }
    };

    this.chatTimerId = setTimeout(check, 100);
  }

  protected updated(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    if (changedProperties.has('env') || changedProperties.has('org')) {
      this.init();
    }
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();

    clearTimeout(this.chatTimerId);
  }

  init() {
    const { assignedProjects, userInfo } = this.context;

    if (assignedProjects) {
      this.megaMenuState.projects = assignedProjects || [];
      this.megaMenuState.selectedProject =
        // @todo - check this
        this.megaMenuState.projects.length > 0
          ? this.megaMenuState.projects.find(
              ({ project: { id } }) => id === this.projectId
            )
          : undefined;

      this.megaMenuState.viewingProject = this.megaMenuState.selectedProject;
      this.requestUpdate();
      this.handleClickMegaMenuIcon();
    }

    if (userInfo?.settings?.defaultLanguage) {
      this.lang = userInfo.settings.defaultLanguage.split('-')[0];
    }
  }

  // Auto-scroll to the selected item.
  // eslint-disable-next-line class-methods-use-this
  handleClickMegaMenuIcon() {
    setTimeout(() => {
      // target the popover itself vs whatever is initially rendered WITHIN this component --> needs to work with new portal placement
      const popoverElem = document.querySelector('#megamenu-popover');

      if (popoverElem) {
        const selectedItems =
          popoverElem.querySelectorAll<MenuItemWC>('ps-menu-item');
        const selectedItem = Array.from(selectedItems).filter(
          (item) => item.selected === true
        );

        const scrollBarContainer = popoverElem.querySelector(
          '.c-mega-menu__scrollbar'
        ) as ScrollbarWC;

        const scrollableContainer =
          scrollBarContainer?.simplebar?.getScrollElement();

        if (selectedItem[0] && scrollableContainer) {
          scrollIntoView(
            selectedItem[0],
            scrollableContainer,
            'vertical',
            'auto'
          );
        }
      }
    }, 0);
  }

  handleManagerToolClick(route?: string) {
    if (!route || !this.onManagerToolClick) return;
    // To avoid bug that mega menu still open after clicking and during route reloading
    document
      .querySelector('ps-universal-nav')
      ?.shadowRoot?.querySelector('ps-mega-menu')
      ?.shadowRoot?.querySelector('#megamenu-trigger')
      ?.dispatchEvent(new Event('click', { bubbles: true }));
    this.onManagerToolClick(route);
  }

  getAppLogoName(): keyof typeof logos | undefined {
    switch (this.app) {
      case ProductName.Organization:
        return 'manager';
      case ProductName.AgentAssist:
        return 'agentAssist';
      case ProductName.Analytics:
        return 'analytics';
      case ProductName.ProStudio:
        return 'proStudio';
      default:
        return 'manager';
    }
  }

  renderTools() {
    return html`
      <ps-grid>
        ${map(
          this.megaMenuState.selectedProject?.availableProducts || [],
          (product) => html`
            <ps-grid-cell xsmall="6" medium="4">
              <ps-card
                test-id=${`mega-menu-product-cta-${product.label?.replace(' ', '-').toLowerCase()}`}
                size="large"
                interactive
                ?disabled=${product.disabled}
                .href=${product.name === ProductName.Organization &&
                this.onManagerToolClick
                  ? undefined
                  : product.url}
                @click=${product.name === ProductName.Organization &&
                this.onManagerToolClick
                  ? () => this.handleManagerToolClick(product.url)
                  : undefined}
              >
                <ps-stack
                  gutter="xsmall"
                  direction="column"
                  alignItems="center"
                >
                  <ps-image
                    src=${logos[product.logo]}
                    width="40px"
                    height="40px"
                  ></ps-image>
                  <ps-text-body size="small" truncate
                    >${product.label}</ps-text-body
                  >
                </ps-stack>
              </ps-card>
            </ps-grid-cell>
          `
        )}
        ${!this.megaMenuState.selectedProject
          ? html`
              <ps-grid-cell xsmall="12" medium="12">
                <ps-stack gutter="large" direction="column" alignItems="center">
                  <ps-image
                    src=${megaMenuEmptyStateIcon}
                    width="110px"
                    height="110px"
                  ></ps-image>
                  <ps-text
                    size="3xsmall"
                    variant="tertiary"
                    font-weight="medium"
                  >
                    ${i18n.t('navigation:mega.content.empty.part1') ||
                    'Select a'}
                    <span class="c-mega-menu__project-word-color"
                      >${i18n.t('navigation:mega.content.empty.part2') ||
                      'Project'}</span
                    >
                    ${i18n.t('navigation:mega.content.empty.part3') ||
                    'from the menu to get started'}
                  </ps-text>
                </ps-stack>
              </ps-grid-cell>
            `
          : nothing}
      </ps-grid>
    `;
  }

  handleProjectSelect(event: MouseEvent, project: CommonProject) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const selectedProject =
      this.megaMenuState.selectedProject?.project.id === project.project.id
        ? undefined
        : project;

    this.megaMenuState.selectedProject = selectedProject;
    this.requestUpdate();
    this.onProjectSelect?.(selectedProject?.project.id);
  }

  renderProjects() {
    const { projects, selectedProject } = this.megaMenuState;
    if (this.hasLoadingProjectsError) {
      return html`
        <ps-menu-item disabled>
          ${i18n.t('navigation:mega.sidebar.error', {
            defaultValue: 'Somethings went wrong. Please reload page.',
          })}
        </ps-menu-item>
      `;
    }

    if (this.isLoadingProjects) {
      return html`
        <div class="c-mega-menu__projects-loader">
          <ps-spinner></ps-spinner>
        </div>
      `;
    }

    if (projects.length) {
      return html`
        ${map(
          projects,
          (project) => html`
            <ps-menu-item
              ?selected=${selectedProject?.project.id === project.project.id}
              @click=${(event: MouseEvent) =>
                this.handleProjectSelect(event, project)}
            >
              ${project?.project.name}
            </ps-menu-item>
          `
        )}
      `;
    }

    return html`<ps-menu-item disabled
      >${i18n.t('navigation:mega.sidebar.empty') ||
      'No available projects'}</ps-menu-item
    >`;
  }

  renderProjectButtonLabel() {
    const appLogoName = this.getAppLogoName();
    const appLogo = html`<ps-image
      src=${ifDefined(appLogoName && logos[appLogoName])}
      width="24px"
      height="24px"
      slot="suffix"
    ></ps-image>`;

    if (
      this.megaMenuState.viewingProject &&
      !!this.app &&
      this.app !== ProductName.Organization
    ) {
      return html` ${(this.megaMenuState.viewingProject.project.files.length >
          0 &&
          html`<ps-image
            src=${this.megaMenuState.viewingProject.project.files[0].url}
            width="32px"
            height="32px"
            slot="prefix"
            radius="small"
          ></ps-image>`) ||
        nothing}
        <span style="max-width: 150px; display: inline-block;">
          <ps-text-subtitle truncate variant="secondary"
            >${this.megaMenuState.viewingProject.project.name}</ps-text-subtitle
          ></span
        >${appLogo}`;
    }

    return html`<ps-image
      src=${ifDefined(appLogoName && logos[appLogoName])}
      width="24px"
      height="24px"
    ></ps-image>`;
  }

  // eslint-disable-next-line class-methods-use-this
  renderGoToHome() {
    if (this.hideGoHome) return nothing;

    return html`
      <ps-menu-item
        variant="ghost"
        @click=${() => handleGoHome(this.context?.accountId)}
        class="c-mega-menu__home-button"
      >
        ${i18n.t('navigation:mega.sidebar.backToHome') || 'Go Home'}
        <ps-icon name="arrow-left" slot="prefix"></ps-icon>
      </ps-menu-item>
    `;
  }

  startChat() {
    if (!this.chatLink) return;

    window.open(
      this.chatLink,
      '_blank',
      'width=600,height=400,right=0,bottom=0,toolbar=no,status=no,menubar=no,location=no'
    );
  }

  renderProjectSelector() {
    return html`
      <style>
        .c-mega-menu__project-selector::part(button) {
          padding: 8px;
        }
      </style>
      <ps-icon name="slash" size="xsmall" color="gray"></ps-icon>
      <ps-button
        variant="ghost"
        id="megamenu-trigger"
        @click=${this.handleClickMegaMenuIcon}
        size="large"
        class="c-mega-menu__project-selector"
      >
        ${this.renderProjectButtonLabel()}
        <ps-icon slot="suffix" name="chevrons-up-down" size="xsmall"></ps-icon>
      </ps-button>
      <ps-popover
        id="megamenu-popover"
        trigger="megamenu-trigger"
        placement="bottom-start"
        width="auto"
        .hasArrow=${false}
      >
        <style>
          .c-mega-menu {
            width: 719px;
            max-width: calc(100vw - 82px);
          }

          .c-mega-menu__menu-header {
            --ps-theme-text-secondary-color: #8573e7;
          }

          .c-mega-menu__scrollbar-wrapper::after {
            content: '';
            position: absolute;
            display: block;
            left: -12px;
            right: -12px;
            bottom: 0;
            border-bottom: 1px solid #dfe1e5;
          }

          .c-mega-menu__project-word-color {
            color: #62697c;
          }

          .c-mega-menu__home-button {
            margin: 4px 12px 4px 0;
          }

          @media (min-width: 768px) {
            .c-mega-menu {
              padding: 16px 0;
            }

            .c-mega-menu__scrollbar-wrapper {
              margin-right: -12px;
              height: 100%;
              max-height: 360px;
              display: flex;
              flex-direction: column;
            }

            .c-mega-menu__menu {
              padding-right: 12px;
            }

            .c-mega-menu__scrollbar-wrapper::after {
              top: -28px;
              right: 0;
              left: auto;
              bottom: -28px;
              border-bottom: 0;
              border-right: 1px solid #dfe1e5;
            }

            .c-mega-menu__content {
              display: flex;
              flex-direction: column;
              height: 100%;
              max-height: 360px;
              overflow: auto;
              padding-left: 28px;
              padding-right: 16px;
            }

            .c-mega-menu__helper {
              margin-top: auto;
            }

            .c-mega-menu__callout {
              padding-left: 0.75rem;
            }
            .c-mega-menu__projects-loader {
              height: 225px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        </style>
        <div class="c-mega-menu">
          <ps-grid gutter="large" rowGutter="large" alignItems="stretch">
            <ps-grid-cell xsmall="12" medium="4">
              <div class="c-mega-menu__scrollbar-wrapper">
                <ps-scrollbar
                  force-visible
                  disable-auto-hide
                  class="c-mega-menu__scrollbar"
                >
                  <ps-menu class="c-mega-menu__menu">
                    <ps-text
                      class="c-mega-menu__menu-header"
                      size="3xsmall"
                      variant="secondary"
                      font-weight="medium"
                    >
                      ${i18n.t('navigation:mega.sidebar.title') || 'Projects'}
                    </ps-text>
                    ${this.renderProjects()}
                  </ps-menu>
                </ps-scrollbar>
                ${this.renderGoToHome()}
              </div>
            </ps-grid-cell>
            <ps-grid-cell xsmall="12" medium="8">
              <div class="c-mega-menu__content">
                <ps-text size="3xsmall" variant="tertiary" font-weight="medium">
                  ${i18n.t('navigation:mega.content.title') || 'Shortcuts'}
                </ps-text>
                <ps-spacer></ps-spacer>
                ${this.renderTools()}
                <ps-spacer size="xsmall"></ps-spacer>
                ${this.chatLink
                  ? html`
                      <div class="c-mega-menu__helper">
                        <ps-callout theme="light" size="small">
                          <div class="c-mega-menu__callout">
                            <ps-text
                              size="3xsmall"
                              variant="secondary"
                              font-weight="medium"
                            >
                              ${i18n.t('navigation:mega.content.help.part1') ||
                              'Need help with something? Contact a specialist'}
                              <a href="#" @click=${this.startChat}>
                                ${i18n.t(
                                  'navigation:mega.content.help.part2'
                                ) || 'here'} </a
                              >.
                            </ps-text>
                          </div>
                        </ps-callout>
                      </div>
                    `
                  : nothing}
              </div>
            </ps-grid-cell>
          </ps-grid>
        </div>
      </ps-popover>
    `;
  }

  renderOrgSelector() {
    return html`
      <style>
        .c-mega-menu__org-selector {
          margin-inline-start: -8px;
        }
        .c-mega-menu__org-selector::part(button) {
          padding: 8px;
        }
      </style>
      <ps-button
        variant="ghost"
        id="org-selector-trigger"
        data-testid="org-selector-trigger"
        size="large"
        class="c-mega-menu__org-selector"
      >
        ${(this.selectedOrg.icon &&
          html`<ps-image
            src=${this.selectedOrg.icon}
            width="32px"
            height="32px"
            slot="prefix"
            radius="small"
          ></ps-image>`) ||
        html`<ps-avatar
          size="medium"
          label=${`${this.selectedOrg.name.split(' ')[0]}`}
          shape="square"
          src=${ifDefined(this.selectedOrg.icon)}
          slot="prefix"
        ></ps-avatar>`}
        <span style="max-width: 150px; display: inline-block;">
          <ps-text-subtitle truncate variant="secondary"
            >${this.selectedOrg.name}</ps-text-subtitle
          ></span
        >
        <ps-icon slot="suffix" name="chevrons-up-down" size="xsmall"></ps-icon>
      </ps-button>
      <ps-popover
        id="org-selector-trigger"
        trigger="org-selector-trigger"
        placement="bottom-start"
        width="auto"
        .hasArrow=${false}
      >
        ${this.context?.accountId || this.context.userInfo?.defaultAccount
          ? html`
              <ps-menu-org-selector
                org=${ifDefined(
                  this.context?.accountId ||
                    this.context.userInfo?.defaultAccount
                )}
                .onSelectionChange=${this.onSelectionChange}
                ?isDisabled=${this.isDisabledOrgs}
              ></ps-menu-org-selector>
            `
          : undefined}
      </ps-popover>
    `;
  }

  protected render() {
    return html`
      <div style="display: flex; align-items: center;">
        ${(this.isDisabledOrgs &&
          !this.selectedOrg.id &&
          html`<ps-spinner></ps-spinner>`) ||
        html`${this.renderOrgSelector()}
        ${this.hideGoHome && this.app === ProductName.Organization
          ? nothing
          : this.renderProjectSelector()}`}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-mega-menu': MegaMenuWC;
  }

  enum PSElementTagNameMap {
    'ps-mega-menu' = 'ps-mega-menu',
  }
}
